<template>
    <div class="wrapper">

        <section class="encabezado -programas">
            <h1>Programas</h1>
        </section>


        <section class="ficha-recurso -programa container" v-if="data">
            
            <router-link :to="$t('links.programas')" class="link-arrow -inverse">Volver</router-link>

            <div class="top" v-if="data">


                <div class="contenedor-imagen" v-if="data.image">
                    <img v-if="data.image" :src="data.image" :alt="data.name">
                </div>                

                <div class="contenedor-textos">
                    <h2>{{data.name}}</h2>
                    <h3 v-html="data.subtitle"></h3>
                    <p class="icontext -sesiones">Número de sesiones: <strong>{{data.sessions_number}}</strong></p>
                    <p class="icontext -duracion" v-if="$route.params.id != 'mentoring-alumni-uc3m'">Duración: <strong>{{data.start_date | parsedate}} a {{data.end_date | parsedate}}</strong></p>
                    <!-- <p class="icontext -metodologia">Metodología: <strong>{{domains.preferences[data.methodology]}}</strong></p> -->
                    <p class="icontext -inscripcion">Inscripción de candidaturas: <strong>{{(data.can_sub_public) ? 'Abierta' : 'Cerrada'}}</strong></p>

                    <router-link class="link-arrow" :to="$t('links.contacto')+'/programa/'+data.id">Solicitar ayuda / Reportar incidencia</router-link>
                    
                    <a v-if="data.can_sub && islogged" @click="requestInscripcion" class="btn" :loading="loading">Inscribirme a este programa</a>
                    
                    
                    <p class="reason" v-if="!data.can_sub && data.close_reason == 3">Ya estás inscrito/a a este programa</p>
                    <p class="reason" v-if="!data.can_sub && data.close_reason == 1">Este programa ya no acepta más miembros</p>
                    <p class="reason" v-if="!data.can_sub && data.close_reason == 2">Para adscribirte a este programa, por favor usa el formulario de "Solicitar ayuda"</p>
                    

                </div>


            </div>

            <div class="bottom" v-if="data">
                <template v-for="(el,key) in data.body">

                    <template v-if="el">
                        <div :key="key" v-if="el.tipo =='texto'" class="parrafo">
                            <p v-html="el.texto"></p>
                        </div>

                        <div :key="key" class="contenedor-imagen" v-if="el.tipo == 'imagen'">
                            <img :src="el.enlace" v-if="el.tipo == 'imagen'" class="img-full">
                            <span v-html="el.pie" v-if="el.tipo == 'imagen'" class="pie"></span>
                        </div>

                        <h4 :key="key" :nivel="el.nivel" v-if="el.tipo == 'destacado'" class="destacado"
                            v-html="el.texto"></h4>

                        <h2 :key="key" v-if="el.tipo == 'titulo'" class="title--sup -green-line -black"
                            v-html="el.value"></h2>

                        <iframe :key="key" :src="$options.filters.urlToID(el.enlace)"
                            v-if="el.tipo == 'video'"></iframe>

                        <div :key="key" class="contenedor-link row-center" v-if="el.tipo == 'link'">

                            <div class="link row-start">
                                <a :href="el.enlace" class="enlace-cover" rel="noopener" target="_blank"></a>
                                <img v-if="!!el.meta.image" :src="el.meta.image.url" alt="">
                                <h2>{{el.meta.title}}</h2>
                                <p>{{el.meta.description}}</p>
                            </div>

                        </div>

                        <div :key="key" class="descargable row-center" v-if="el.tipo == 'file'">
                            <a :href="el.enlace" class="enlace-cover" rel="noopener" target="_blank"></a>
                            <p v-if="!!el.titulo">{{el.titulo}} <a href="" class="download-link">Descargar</a></p>
                        </div>

                        <ul :key="key" v-if="el.tipo == 'lista' && el.estilo == 'unordered'">
                            <li :key="key" v-for="(el,key) in el.objectos" v-html="el"></li>
                        </ul>

                        <ol :key="key" v-if="el.tipo == 'lista' && el.estilo == 'ordered'">
                            <li :key="key" v-for="(el,key) in el.objectos" v-html="el"></li>
                        </ol>

                        
                        <div :key="key" class="boton" v-if="el.tipo == 'boton'">
                            <a target="_blank" :href="el.enlace" class="btn -skull-blue">{{el.texto}}</a>
                        </div>


                        <div class="table-container" :key="key" v-if="el.tipo == 'tabla'">
                            <table>
                                <tr v-for="(td,tdkey) in el.filas" :key="tdkey">
                                    <td :key="ekey" v-for="(e,ekey) in td" v-html="e">
                                        
                                    </td>
                                </tr> 
                            </table>
                        </div>

                    </template>

                </template>


                <h2 class="program-title">Objetivo del programa</h2>
                <p v-html="data.objective"></p>


                <h2 class="program-title">Contacto</h2> 
                <p>{{data.contact_name}} Puedes ponerte en contacto a través de <a :href="'mailto:'+data.contact_email">{{data.contact_email}}</a></p>


                <template v-if="data.mentee_participate">
                    <h2 class="program-title">Requisitos del mentee</h2>
                    <p>{{data.mentee_participate}}</p>
                </template>

                <template v-if="data.profesor_participate">    
                    <h2 class="program-title">Requisitos del mentor/a</h2>
                    <p>{{data.profesor_participate}}</p>
                </template>   


                <a v-if="data.can_sub && islogged" @click="requestInscripcion" class="btn" :loading="loading">Inscribirme a este programa</a>

                <a @click="register" class="btn" v-if="!islogged && data.can_sub_public" >Inscribirme a este programa</a>




            </div>

        </section>


        <div class="loading" v-else></div>



    </div>
</template>


<script>

    import { mapActions, mapGetters } from 'vuex';

    import moment from 'moment';


    import router from '@/router';


    export default {
        name: 'ficha-programa',
        mounted() {
            this.setData();
        },
        data: () => ({
            data: '',
            loading : false
        }),
        computed: {
            ...mapGetters({
                domains: 'getDomains',
                islogged : 'getLogged',
            })
        },
        methods: {
            ...mapActions(['requestPrograma','inscripcionPrograma','requestRegisterInProgram']),
            setData() {
                this.requestPrograma(this.$route.params.id).then(r => {
                    this.data = r;
                })
            },
            /* validateDates(start, end) {
                const today = new Date();
                const startDate = new Date(start);
                const endDate = new Date(end);
                return today >= startDate && today <= endDate;
            }, */
            requestInscripcion(){
                this.loading = true;
                this.inscripcionPrograma(this.data.id).then( r => {
                    if(r){
                        this.data.can_sub = false;
                        this.data.close_reason = 3;
                        this.loading = false;

                        this.setData();
                    }
                });
            },
            register(){
                window.localStorage.setItem('program_id', this.data.id);
                router.push('/login')
            }
        },
        filters: {
            parsedate(value) {
                return moment(value).format("DD/MM/YYYY")
            },
            urlToID(value) {

                let string;

                if (value.includes('youtube')) {
                    string = 'https://www.youtube.com/embed/' + value.split("?v=")[1];
                } else if (value.includes('vimeo')) {
                    string = 'https://player.vimeo.com/video/' + value.split("/").pop();
                }

                return string;
            }
        }
    }
</script>